import React from 'react'
import Layout from '../components/layout/layout'
import Title from '../components/title/title'

export default () => (
	<Layout>
		<Title title='About' />
		<p>Hi! I'm Mark Peterson. I'm a software developer. Graduated college with a BA in Computer Science in 2015 and have been working professionally as a developer since 2014. I love finding optimal solutions for solving problems with software. Dev work primarily focused on the Microsoft stack of tech between Azure, .NET, and MS SQL Server, but also like working with fun js frameworks like React, Angular, and Vue.</p>
		<p>Like to cook good food (and eat it too). Strategy, puzzle, and RPGs are my go to genres for video gaming.</p>
		<p>If you think you'd want to contact me reach out on LinkedIn.</p>
	</Layout>
)
